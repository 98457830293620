<div id="top-spacer"></div>
<div id="top-ads" *ngIf="headerAds !== null">
	<app-ads [ads]="headerAds"></app-ads>
</div>
<div id="header-top">
	<div id="top">
		<div class="container cb">
			<div id="top-left">
				<ng-container *ngFor="let topTag of topTags; let i = index;">
					<a [routerLink]="['/cimke', topTag]" class="top-left-item">
						<ng-container *ngIf="i === 0">
							<span class="top-left-item-tag-top">TOP<span></span></span>
						</ng-container>
						<span class="top-left-item-inner">{{topTag}}</span>
					</a>
				</ng-container>
			</div>
			<div id="top-right">
				<span class="top-right-item today"><span class="top-right-item-inner" (click)="ee($event)">{{today}}</span></span>
				<span class="top-right-item weather" *ngIf="weatherInfo !== null"><span class="top-right-item-inner" [title]="weatherInfo.text"><span class="artill_clean_weather_icons">{{getWeatherInfoIcon(weatherInfo.icon)}}</span> {{weatherInfo.temperature}} ℃</span></span>
			</div>
		</div>
	</div>
	<div id="header">
		<div class="container cb">
			<div id="header-left">
				<a [routerLink]="['/']" class="title"><img class="logo" [src]="'assets/images/logo/logo-' + logo + '.png'" /></a>
			</div>
			<div id="header-right">
				<div class="mobile-menu-button" (click)="toggleMobileMenu()">
					<div></div>
					<div></div>
					<div></div>
				</div>
				<div class="menu" *ngIf="!search">
					<span class="menu-element" *ngFor="let topic of topics; let i = index;" (mouseover)="topicMouseover(topic.id)" (mouseleave)="topicMouseleave()" [ngClass]="{'active': topic.id === activeTopicId}">
						<a class="menu-link" routerLink="/">{{topic.name}}</a>
						<a class="menu-link-hover" [routerLink]="topic.slug" [ngStyle]="{'background-color': topic.backgroundColor, 'color': topic.textColor}" *ngIf="topic.externalUrl === ''">{{topic.name}}</a>
						<a class="menu-link-hover" [href]="topic.externalUrl" target="_blank" [ngStyle]="{'background-color': topic.backgroundColor, 'color': topic.textColor}" *ngIf="topic.externalUrl !== ''">{{topic.name}}</a>
						<div class="submenu" [ngStyle]="{'border-top-color': topic.backgroundColor}" [ngClass]="{'right': i >= topics.length / 2}" *ngIf="getTopicsByParent(topic.id).length > 0">
							<ng-container *ngFor="let subtopic of getTopicsByParent(topic.id)">
								<a class="submenu-link" [routerLink]="subtopic.slug" *ngIf="subtopic.externalUrl === ''">{{subtopic.name}}</a>
								<a class="submenu-link" [href]="subtopic.externalUrl" target="_blank" *ngIf="subtopic.externalUrl !== ''">{{subtopic.name}}</a>
							</ng-container>
						</div>
					</span>
				</div>
				<img id="search-button" src="/assets/images/search.png" (click)="toggleSearch()" [ngClass]="{'active': search}" alt="Keresés" />
				<input #searchInput id="search" placeholder="Keresés..." type="text" [ngClass]="{'active': search}" (keyup)="keyup($event)" />
			</div>
		</div>
	</div>
</div>
<div id="mobile-menu" [ngClass]="{'active': mobileMenuActive}">
    <div id="mobile-menu-top">
        <div id="mobile-menu-top-left">
			<img class="logo-mobile" [src]="'assets/images/logo/logo-' + logo + '.png'" />
		</div>
        <div id="mobile-menu-top-right">
            <div id="mobile-menu-close" (click)="toggleMobileMenu()">
                <div id="mobile-menu-close-line-1"></div>
                <div id="mobile-menu-close-line-2"></div>
            </div>
        </div>
    </div>
    <div id="mobile-menu-search">
        <input id="search-mobile" placeholder="Keresés..." type="text" [ngClass]="{'active': search}" (keyup)="keyup($event)" />
    </div>
    <div id="mobile-menu-content">
        <div id="mobile-menu-elements">
            <div class="mobile-menu-element" *ngFor="let topic of topics">
                <div class="mobile-menu-element-top" [ngStyle]="{'background-color': topic.backgroundColor, 'color': topic.textColor}">
                    <a class="mobile-menu-element-left" [routerLink]="topic.slug" (click)="closeMobileMenu()" *ngIf="topic.externalUrl === ''">{{topic.name}}</a>
                    <a class="mobile-menu-element-left" [href]="topic.externalUrl" target="_blank" (click)="closeMobileMenu()" *ngIf="topic.externalUrl !== ''">{{topic.name}}</a>
                    <span class="mobile-menu-element-right">
                        <div class="mobile-menu-element-right-dropdown" *ngIf="getTopicsByParent(topic.id).length > 0" (click)="showMobileMenuTopic(topic.id)">
                            <div class="mobile-menu-element-right-dropdown-line-1" [ngStyle]="{'background-color': topic.textColor}"></div>
                            <div class="mobile-menu-element-right-dropdown-line-2" [ngStyle]="{'background-color': topic.textColor}"></div>
                        </div>
                    </span>
                </div>
                <div class="mobile-menu-element-submenu" *ngIf="mobileMenuActiveTopicId === topic.id">
					<ng-container *ngFor="let subtopic of getTopicsByParent(topic.id)">
						<a class="mobile-menu-element-submenu-item" [routerLink]="subtopic.slug" (click)="closeMobileMenu()" *ngIf="subtopic.externalUrl === ''">{{subtopic.name}}</a>
						<a class="mobile-menu-element-submenu-item" [href]="subtopic.externalUrl" target="_blank" (click)="closeMobileMenu()" *ngIf="subtopic.externalUrl !== ''">{{subtopic.name}}</a>
					</ng-container>
                </div>
            </div>
        </div>
    </div>
</div>