import { Injectable } from "@angular/core";
import { AdsItem, NewsShortItem, Topic, WeatherInfo } from "./interfaces";

@Injectable({
    providedIn: 'root'
})
export class InitService {
    topics: Topic[] = [];
    popularTags: string[] = [];
    popularNews: NewsShortItem[] = [];
    featuredNews: NewsShortItem[] = [];
	latestNews: NewsShortItem[] = [];
    siteStatus: string = null;
    topTags: string[] = [];
    today: string = null;
    weatherInfo: WeatherInfo = null;
	footerHtml: string = '';
	headerHtml: string = '';
	headerAds: AdsItem | null = null;
	popupAds: AdsItem | null = null;
	floatingAds: AdsItem | null = null;
    getTopicById(id: number): Topic {
        return this.topics.find((topic: Topic) => topic.id === id) || null;
    }
    getTopicsByIds(ids: number[]): Topic[] {
        return this.topics.filter((topic: Topic) => ids.includes(topic.id));
	}
}