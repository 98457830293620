<ng-container *ngIf="ads.kind === AdsKind.OWN && ads.subKind === AdsSubKind.IMAGE">
	<div class="ads-name-title">Hirdetés</div>
	<div class="ads-container">
		<a class="ads-container-url" [href]="ads.url" target="_blank" [title]="'Hirdetés'" (click)="adsClick()">
			<img class="ads-image" [src]="url" [alt]="'Hirdetés'" [ngStyle]="{'width': this.width}" />
		</a>
	</div>
</ng-container>
<ng-container *ngIf="ads.kind === AdsKind.OWN && ads.subKind === AdsSubKind.VIDEO">
	<div class="ads-name-title">Hirdetés</div>
	<div class="ads-container">
		<a class="ads-container-url" [href]="ads.url" target="_blank" [title]="'Hirdetés'" (click)="adsClick()">
			<video class="ads-video" webkit-playsinline playsinline [src]="url" [autoplay]="'autoplay'" [controls]="true" [muted]="'muted'" loop></video>
		</a>
	</div>
</ng-container>
<ng-container *ngIf="ads.kind === AdsKind.OWN && ads.subKind === AdsSubKind.FTP">
	<div class="ads-name-title">Hirdetés</div>
	<div class="iframe-wrapper-outer" [ngStyle]='{"max-width.px": widthNumber}'>
		<div class="iframe-wrapper" [ngStyle]='{"padding-bottom.%": (height / widthNumber) * 100, "max-width.px": widthNumber, "max-height.px": height, "margin": "0 auto"}'>
			<iframe [src]="ads.ftpUrl|saferesourceurl" [width]="widthNumber" [height]="height"></iframe>
		</div>
	</div>
</ng-container>
<div *ngIf="ads.kind === AdsKind.OUTER_SOURCE">
	<div class="ads-name-title">Hirdetés</div>
	<div class="outer-ad" #adholder></div>
</div>