import { Component, Inject } from '@angular/core';
import { NewsShortItem } from 'src/app/interfaces';
import { HOST } from 'src/app/tokens';
import { InitService } from '../../init.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  popularTags: string[] = [];
  featuredNews: NewsShortItem[] = [];
  popularNews: NewsShortItem[] = [];

  constructor(
    private initService: InitService,
	@Inject(HOST) public host: string
  ) {
    this.popularTags = this.initService.popularTags;
    this.popularNews = this.initService.popularNews;
    this.featuredNews = this.initService.featuredNews;
  }
}
