<app-header></app-header>
<div id="content">
	<router-outlet></router-outlet>
</div>
<div class="popup-ad" *ngIf="floatingAds !== null">
	<div class="ad" [ngStyle]="{'animation': getFloatingAdAnimation() + ' 0.8s cubic-bezier(0.61, 1, 0.88, 1)'}">
		<div class="ad-close" (click)="hideFloatingAd()">X Bezárás</div>
		<app-ads [ads]="floatingAds"></app-ads>
	</div>
</div>
<app-footer></app-footer>
<div id="message" *ngIf="message !== null">
	<div id="message-close" (click)="closeMessage()">x</div>
	<div id="message-title">{{message.title}}</div>
	<div id="message-text" [innerHTML]="message.text|safehtml"></div>
</div>
<div id="cookie-policy" *ngIf="cookiePolicyVisible" [ngClass]="{'active': cookiePolicyVisible}">
	<div id="cookie-policy-content">
		<div id="cookie-policy-content-title">Cookie szabályzat</div>
		<div class="cookie-policy-content-data">A weboldal sütiket (cookie-kat) használ, hogy biztonságos böngészés mellett a legjobb felhasználói élményt nyújtsa.</div>
		<div class="cookie-policy-content-data">Az <a target="_blank" href="assets/documents/civishir-hu-adatkezelesi-tajekoztato.pdf">adatvédelmi nyilatkozatunkban</a> részletes tájékoztatást találhat az általunk kezelt adatokról.</div>
		<div id="cookie-policy-content-buttons">
			<span class="cookie-policy-content-button" (click)="hideCookiePolicy()">Megértettem</span>
		</div>
	</div>
</div>