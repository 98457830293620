import { Component, Input, OnInit } from '@angular/core';
import { TITLE_STYLES } from 'src/app/consts';
import { NewsItem, TemplateBlockConfigurationTitleSize, TitleStyle, Topic } from 'src/app/interfaces';
import { InitService } from '../../../init.service';

@Component({
	selector: 'app-news',
	templateUrl: './news.component.html',
	styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

	@Input() news: NewsItem = null;
	@Input() displayImage: boolean = false;
	@Input() displaySubtitle: boolean = false;
	@Input() titleSize: TemplateBlockConfigurationTitleSize = TemplateBlockConfigurationTitleSize.SMALL;
	@Input() displayImageOnLeft: boolean = false;

	topics: Topic[] = [];
	titleStyle: TitleStyle = null;

	getImageSize(url: string): string {
		if (this.news === null) {
			return '';
		}
		return url;
	}
	
	ngOnInit(): void {
		this.topics = this.initService.getTopicsByIds(this.news.topicIds);
		this.titleStyle = TITLE_STYLES.find(t => t.value === this.news.titleStyle) || null;
	}

	constructor(
		private initService: InitService
	) { }
}
