import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, inject, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { timer } from 'rxjs';
import { InitService } from './init.service';
import { AdsItem, AdsKind, AdsSubKind, Message } from './interfaces';
import { MessageService } from './services/message.service';
import { GOOGLE_ANALYTICS_TRACKING_CODE, HOST } from './tokens';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

	message: Message = null;
	cookiePolicyVisible: boolean = false;
	floatingAds: AdsItem | null = null;

	hideCookiePolicy(): void {
		this.cookiePolicyVisible = false;
		this.cookieService.set('civispopupcookiepolicy', '1', 365, '/');
	}

	closeMessage(): void {
		this.messageService.onMessage.next(null);
	}

	insertGa(): void {
		const script: HTMLScriptElement = this.document.createElement('script');
		script.async = true;
		script.src = 'https://www.googletagmanager.com/gtag/js?id=' + this.googleAnalyticsTrackingCode;
		script.onload = () => {
			const innerScript: HTMLScriptElement = this.document.createElement('script');
			innerScript.innerHTML = `
				window.dataLayer = window.dataLayer || [];
				function gtag(){dataLayer.push(arguments);}
				gtag('js', new Date());
				gtag('config', '${this.googleAnalyticsTrackingCode}');
			`;
			this.document.head.appendChild(innerScript);
		}
		this.document.head.appendChild(script);
	}

	insertEp(): void {
		const script: HTMLScriptElement = this.document.createElement('script');
		script.async = true;
		script.src = '//ex1tp.com/js/ep/v2/ep.js';
		script.onload = () => {
			const innerScript: HTMLScriptElement = this.document.createElement('script');
			innerScript.innerHTML = `
				window._extpp = window._extpp || [];
				_extpp.push({prop: '${this.host}'});
			`;
			this.document.head.appendChild(innerScript);
		}
		this.document.head.appendChild(script);
	}

	initPopupAds(): void {
		if (this.initService.floatingAds === null) {
			return;
		}
		if (this.cookieService.check('civisfloating')) {
			return;
		}

		timer((this.initService.floatingAds.delay || 0) * 1000).subscribe(() => {
			this.floatingAds = this.initService.floatingAds;
			if (this.floatingAds.duration !== null) {
				timer(this.floatingAds.duration * 1000).subscribe(() => {
					this.floatingAds = null;
				});
			}
		});
	}

	getFloatingAdAnimation(): string {
		if (this.floatingAds === null) {
			return 'none';
		}
		switch (this.floatingAds.animation) {
			case 'FROM_TOP': return 'fromTop';
			case 'FROM_LEFT': return 'fromLeft';
			case 'FROM_RIGHT': return 'fromRight';
			case 'FROM_BOTTOM': return 'fromBottom';
			case 'ZOOM_IN': return 'zoomIn';
		}
		return 'none';
	}

	hideFloatingAd(): void {
		this.floatingAds = null;
		this.cookieService.set('civisfloating', '1', 1, '/');
	}

	ngOnInit(): void {
		if (isPlatformBrowser(this.platformId)) {
			this.cookiePolicyVisible = !this.cookieService.check('civispopupcookiepolicy');
			if (!this.cookiePolicyVisible) {
				this.initPopupAds();
			}
		}
		this.messageService.onMessage.subscribe((message: Message) => {
			this.message = message;
		});
	}

	constructor(
		private messageService: MessageService,
		private cookieService: CookieService,
		private initService: InitService,
		@Inject(PLATFORM_ID) private platformId: Object,
		@Inject(DOCUMENT) private document: Document,
		@Inject(GOOGLE_ANALYTICS_TRACKING_CODE) private googleAnalyticsTrackingCode: string,
		@Inject(HOST) private host: string
	) {
		this.insertGa();
		this.insertEp();
	}
}
