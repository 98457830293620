import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { NewsComponent } from './components/elements/news/news.component';
import { GalleryComponent } from './components/elements/gallery/gallery.component';
import { init } from './init';
import { InitService } from './init.service';
import { FreshnewsComponent } from './components/elements/freshnews/freshnews.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiService } from './api.service';
import { Router } from '@angular/router';
import { TopicComponent } from './components/topic/topic.component';
import { NewsdetailComponent } from './components/newsdetail/newsdetail.component';
import { AdsComponent } from './components/elements/ads/ads.component';
import { SafeHtmlPipe } from './pipes/safehtml.pipe';
import { TagComponent } from './components/tag/tag.component';
import { CiviscafeComponent } from './components/civiscafe/civiscafe.component';
import { SearchComponent } from './components/search/search.component';
import { MessageService } from './services/message.service';
import { InnervoteComponent } from './components/elements/innervote/innervote.component';
import { InnernewsComponent } from './components/elements/innernews/innernews.component';
import { LightgalleryModule } from 'lightgallery/angular/13';
import { ScrollDirective } from './directives/scroll.directive';
import { ShareModule } from 'ngx-sharebuttons';
import { ImpressumComponent } from './components/impressum/impressum.component';
import { TokenService } from './services/token.service';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { SafeResourceUrlPipe } from './pipes/saferesourceurl';

@NgModule({
	declarations: [
		AppComponent,
		HeaderComponent,
		FooterComponent,
		HomeComponent,
		NewsComponent,
		GalleryComponent,
		FreshnewsComponent,
		TopicComponent,
		NewsdetailComponent,
		AdsComponent,
		SafeHtmlPipe,
		TagComponent,
		CiviscafeComponent,
		SearchComponent,
		InnervoteComponent,
		InnernewsComponent,
		ScrollDirective,
		ImpressumComponent,
		SafeResourceUrlPipe
	],
	imports: [
		BrowserModule.withServerTransition({ appId: 'serverApp' }),
		AppRoutingModule,
		HttpClientModule,
		LightgalleryModule,
		ShareModule
	],
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: init,
			deps: [ApiService, InitService, Router, TokenService, MessageService],
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptor,
			deps: [TokenService],
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ErrorInterceptor,
			multi: true
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
