import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CiviscafeComponent } from './components/civiscafe/civiscafe.component';
import { HomeComponent } from './components/home/home.component';
import { ImpressumComponent } from './components/impressum/impressum.component';
import { NewsdetailComponent } from './components/newsdetail/newsdetail.component';
import { SearchComponent } from './components/search/search.component';
import { TagComponent } from './components/tag/tag.component';
import { NewsDetailsAdsResolve, CivisCafeResolve, HomeResolve, NewsDetailResolve, SearchNewsResolve, TagNewsResolve } from './resolvers';

const routes: Routes = [
  { path: '', component: HomeComponent, resolve: {getHomeResponse: HomeResolve}, runGuardsAndResolvers: 'always' },
  { path: 'hir/:slug', component: NewsdetailComponent, resolve: {newsDetail: NewsDetailResolve, ads: NewsDetailsAdsResolve}, runGuardsAndResolvers: 'always' },
  { path: 'cimke/:tag', component: TagComponent, resolve: {news: TagNewsResolve} },
  { path: 'civiscafe', component: CiviscafeComponent, resolve: {getCiviscafe: CivisCafeResolve} },
  { path: 'kereses/:query', component: SearchComponent, resolve: {news: SearchNewsResolve} },
  { path: 'impresszum', component: ImpressumComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
