import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { TopicComponent } from './components/topic/topic.component';
import { InitService } from './init.service';
import { Topic } from './interfaces';
import { TopicNewsResolve } from './resolvers';
import { MessageService } from './services/message.service';
import { TokenService, TokenServiceMode } from './services/token.service';
import { SETTINGS } from './settings';
import { HOST, LOCAL_STORE_KEY } from './tokens';

export function init(apiService: ApiService, initService: InitService, router: Router, tokenService: TokenService, messageService: MessageService): () => Promise<boolean> {
    tokenService.cookiePath = '/';
    tokenService.accessTokenKey = inject(LOCAL_STORE_KEY);
	tokenService.accessToken = tokenService.read(TokenServiceMode.LOCALSTORAGE);

	const host: string = inject(HOST);

    SETTINGS.errorInterceptorCallbackfunction = (httpErrorResponse: HttpErrorResponse) => {
        if (httpErrorResponse.error !== undefined) {
            if (httpErrorResponse.error.message !== undefined && httpErrorResponse.error.id !== undefined) {
                messageService.onMessage.next({
                    title: 'Hiba történt',
                    text: httpErrorResponse.error.id + '<br />' + httpErrorResponse.error.message
                });
            }
        }
    }

    return (): Promise<boolean> => {
        return new Promise((resolve) => {
            initService.topics = [];
			apiService.setApiBaseUrl(SETTINGS.apiBaseUrl);
			apiService.init(host).subscribe(({
				topics,
				popularTags,
				featuredNews,
				popularNews,
				latestNews,
				siteStatus,
				sessionToken,
				topTags,
				today,
				weatherInfo,
				headerAds,
				popupAds,
				footerHtml,
				headerHtml,
				floatingAds
			}) => {
				initService.topics = topics;
				initService.popularTags = popularTags;
				initService.featuredNews = featuredNews;
				initService.latestNews = latestNews;
				initService.footerHtml = footerHtml;
				initService.headerHtml = headerHtml;
				initService.popularNews = popularNews;
				initService.siteStatus = siteStatus;
				initService.topTags = topTags;
				initService.today = today;
				initService.weatherInfo = weatherInfo;
				initService.headerAds = headerAds;
				initService.popupAds = popupAds;
				initService.floatingAds = floatingAds;
				tokenService.accessToken = sessionToken;
				tokenService.cookiePath = '/';
				tokenService.write(TokenServiceMode.LOCALSTORAGE);

				// DEFINE TOPIC URLS
				topics.forEach((topic: Topic) => {
					router.config.push({
						path: topic.slug,
						component: TopicComponent,
						data: { topic },
						runGuardsAndResolvers: 'always',
						resolve: {news: TopicNewsResolve}
					});
				});

				router.config.push({path: '**', redirectTo: '', pathMatch: 'full'})

				resolve(true);
			});
        });
    };
}
