import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { VoteItem, VoteItemAnswer } from 'src/app/interfaces';

@Component({
  selector: 'app-innervote',
  templateUrl: './innervote.component.html',
  styleUrls: ['./innervote.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InnervoteComponent implements OnInit {

  @Input() voteItem: VoteItem = null;
  voted: number = null;

  constructor(
    private las: ApiService,
    private cdr: ChangeDetectorRef
  ) {
    this.cdr.detach();
  }

  ngOnInit(): void {
    this.cdr.detectChanges();
  }

  makeAnswer(answerId: number): void {
    if (this.voted !== null) {
      return;
    }
    this.voted = answerId;
    this.las.answer(this.voteItem.votingId, answerId).subscribe(({answerCounts}) => {
      const keys: string[] = Object.keys(answerCounts);
      const fullCount: number = keys.map((key: string) => answerCounts[key]).reduce((a, b) => a + b, 0);
      keys.forEach((votingId: string) => {
        const answerIdNumber: number = parseInt(votingId, 10);
        const answer: VoteItemAnswer = this.voteItem.answers.find((a) => a.answerId === answerIdNumber);
        if (answer !== null) {
          answer.numberOfVotes = answerCounts[answerIdNumber];
          answer.percent = Math.round((answerCounts[answerIdNumber] / fullCount) * 100);
        }
      })
      this.cdr.detectChanges();
    });
  }

}
