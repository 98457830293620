import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, ActivationEnd, Event, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { processTitleStyles } from 'src/app/helper';
import { NewsItem } from 'src/app/interfaces';
import { UnsubscribeComponent } from '../unsubscribe.component';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagComponent extends UnsubscribeComponent {

  news: NewsItem[] = [];
  tag: string = null;

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    private title: Title
  ) {
    super();
    this.router.events.pipe(takeUntil(this.unsubscribe)).subscribe((event: Event) => {
      if (event instanceof ActivationEnd) {
        this.setData(event.snapshot);
      }
    });
    this.cdr.detach();
  }

  setData(activatedRouteSnapshot: ActivatedRouteSnapshot): void {
    this.tag = activatedRouteSnapshot.paramMap.get('tag');
    this.news = activatedRouteSnapshot.data.news;
    processTitleStyles(this.news);
    this.title.setTitle('Címke - ' + this.tag);
    this.cdr.detectChanges();
  }

}
