import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { timer } from 'rxjs';
import { InitService } from 'src/app/init.service';
import { AdsItem, AdsKind, AdsSubKind, GetHomeResponse, PageBlock } from 'src/app/interfaces';
import { PAGE_TITLE } from 'src/app/tokens';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent {

	pageBlocks: PageBlock[] = [];

	leftSideAds: AdsItem | null = null;
	rightSideAds: AdsItem | null = null;
	popupAds: AdsItem | null = null;
	popupAdVisible: boolean = false;

	getGridTemplateColumns(pageBlock: PageBlock): string {
		return pageBlock.columnConfigurations.map(({ width }) => width === null ? '1fr' : width.toString() + '%').join(' ');
	}

	getGridTemplateAreas(pageBlock: PageBlock): string {
		let gridTemplateAreas: string = '';
		for (let ri = 0; ri < pageBlock.columnConfigurations[0].contents.length; ri++) {
			gridTemplateAreas += '\'';
			for (let ci = 0; ci < pageBlock.columnConfigurations.length; ci++) {
				gridTemplateAreas += "n" + ri.toString() + '' + ci.toString() + ' ';
			}
			gridTemplateAreas += '\'';
		}
		return gridTemplateAreas;
	}

	isPageBlockEqual(pageBlock: PageBlock): boolean {
		if (pageBlock.columnConfigurations.length < 1) {
			return false;
		}
		let equal: boolean = true;
		pageBlock.columnConfigurations.forEach(({ contents, width }) => {
			if (width !== pageBlock.columnConfigurations[0].width) {
				equal = false;
			}
			if (contents.length !== pageBlock.columnConfigurations[0].contents.length) {
				equal = false;
			}
			contents.forEach(({ displayImage, displaySubtitle }) => {
				if (!displayImage || !displaySubtitle) {
					equal = false;
				}
			});
		});
		return equal;
	}

	hidePopupAd(): void {
		this.popupAdVisible = false;
		this.cookieService.set('civispopupadhome', '1', 1, '/');
	}

	constructor(
		private activatedRoute: ActivatedRoute,
		private title: Title,
		private cookieService: CookieService,
		private initService: InitService,
		@Inject(PLATFORM_ID) private platformId: Object,
		@Inject(PAGE_TITLE) private pageTitle: string
	) {
		const getHomeResponse: GetHomeResponse = this.activatedRoute.snapshot.data.getHomeResponse;
		this.pageBlocks = getHomeResponse.pageBlocks;
		this.leftSideAds = getHomeResponse.leftSideAds;
		this.rightSideAds = getHomeResponse.rightSideAds;
		
		if (isPlatformBrowser(this.platformId)) {
			this.popupAdVisible = !this.cookieService.check('civispopupadhome');
			if (this.initService.floatingAds !== null) {
				this.popupAdVisible = false;
			}
			this.popupAdVisible = true;
			if (this.popupAdVisible) {
				if (getHomeResponse.popupAds !== null) {
					timer((getHomeResponse.popupAds.delay || 0) * 1000).subscribe(() => {
						this.popupAds = getHomeResponse.popupAds;
						if (getHomeResponse.popupAds.duration !== null) {
							timer(getHomeResponse.popupAds.duration * 1000).subscribe(() => {
								this.hidePopupAd();
							});
						}
					});
				}
			}
		}
		this.title.setTitle(this.pageTitle);
	}
}
