import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ActivationEnd, Event, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from 'src/app/api.service';
import { processTitleStyles } from 'src/app/helper';
import { Ads, NewsItem, Topic } from 'src/app/interfaces';
import { UnsubscribeComponent } from '../unsubscribe.component';

@Component({
	selector: 'app-topic',
	templateUrl: './topic.component.html',
	styleUrls: ['./topic.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopicComponent extends UnsubscribeComponent {

	news: NewsItem[] = [];
	ads: Ads = {};
	topic: Topic = null;
	page: number = 1;

	loadNews(): void {
		this.apiService.getNews({
			topicId: this.topic.id,
			count: 20,
			start: (this.page - 1) * 20,
			tag: null,
			search: null
		}).pipe(takeUntil(this.unsubscribe)).subscribe((news) => {
			processTitleStyles(news);
			this.news.push(...news);
			this.cdr.detectChanges();
		});
	}

	nextPage(): void {
		this.page++;
		this.loadNews();
	}

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private cdr: ChangeDetectorRef,
		private title: Title,
		private apiService: ApiService
	) {
		super();
		this.topic = this.activatedRoute.snapshot.data.topic;
		this.ads = this.activatedRoute.snapshot.data.ads;
		this.title.setTitle('Rovat - ' + this.topic.name);
		this.router.events.pipe(takeUntil(this.unsubscribe)).subscribe((event: Event) => {
			if (event instanceof ActivationEnd) {
				this.page = 1;
				this.news = event.snapshot.data.news;
				processTitleStyles(this.news);
				this.cdr.detectChanges();
			}
		});
		this.cdr.detach();
	}

}
