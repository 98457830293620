import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ActivationEnd, Event, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from 'src/app/api.service';
import { processTitleStyles } from 'src/app/helper';
import { NewsItem, TemplateBlockConfigurationTitleSize } from 'src/app/interfaces';
import { UnsubscribeComponent } from '../unsubscribe.component';

@Component({
	selector: 'app-search',
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchComponent extends UnsubscribeComponent {

	query: string = null;
	news: NewsItem[] = [];

	TemplateBlockConfigurationTitleSize = TemplateBlockConfigurationTitleSize;
	page: number = 1;

	loadNews(): void {
		this.apiService.getNews({
			topicId: null,
			count: 10,
			start: (this.page - 1) * 10,
			tag: null,
			search: this.query
		}).pipe(takeUntil(this.unsubscribe)).subscribe((news) => {
			processTitleStyles(news);
			this.news.push(...news);
			this.cdr.detectChanges();
		});
	}

	nextPage(): void {
		this.page++;
		this.loadNews();
	}

	constructor(
		private router: Router,
		private cdr: ChangeDetectorRef,
		private title: Title,
		private apiService: ApiService,
		private activatedRoute: ActivatedRoute
	) {
		super();
		this.query = this.activatedRoute.snapshot.paramMap.get('query');
		this.title.setTitle('Keresés - ' + this.query);
		this.router.events.pipe(takeUntil(this.unsubscribe)).subscribe((event: Event) => {
			if (event instanceof ActivationEnd) {
				this.page = 1;
				this.news = event.snapshot.data.news;
				this.cdr.detectChanges();
			}
		});
		this.cdr.detach();
	}
}
