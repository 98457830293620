export interface Configuration {
    apiBaseUrl: string;
}

export enum NewsType {
    'NORMAL' = 'NORMAL',
    'PR' = 'PR',
    'EVENT' = 'EVENT',
    'LIVE' = 'LIVE'
}

export interface NewsDetailGalleryItem {
    name: string;
    downloadable: boolean;
    source: string;
    url: string;
}

export enum NewsEditorContentBlockType {
	EDITOR = 'EDITOR',
	MEDIA = 'MEDIA',
	YOUTUBE = 'YOUTUBE',
	VOTING = 'VOTING',
	NEWS = 'NEWS',
	NEWS_MULTIPLE = 'NEWS_MULTIPLE',
	INSTAGRAM = 'INSTAGRAM',
	TIKTOK = 'TIKTOK',
	TWITTER = 'TWITTER',
	FACEBOOK = 'FACEBOOK',
	VIDEA = 'VIDEA',
	TABLE = 'TABLE',
	GALLERY = 'GALLERY',
	ADS = 'ADS',
	EMBED_HTML = 'EMBED_HTML',
	FILE = 'FILE'
}

export interface NewsEditorContentBlock {
	type: NewsEditorContentBlockType;
	data: any;
}

export interface NewsDetail {
    contentType: NewsType;
    title: string;
    titleStyle: string;
    subtitle: string;
    content: NewsEditorContentBlock[];
    tags: string[];
    mainMedia: InnerMediaItem;
    publicationFromDate: string;
    publisherFullname: string;
    contentSource: string;
    editorName: string;
    eventName: string;
    eventPostcode: string;
    eventCity: string;
    eventAddress: string;
    eventPrice: string;
    eventFree: boolean;
    eventStartDate: string;
    eventEndDate: string;
    eventContact: string;
    topicIds: number[];
    liveType: LiveType;
    startDate: string;
    location: string;
    info: string;
    homeTeam: string;
    guestTeam: string;
    homeScore: string;
    guestScore: string;
    liveEntries: LiveEntry[];
    gallery: NewsDetailGalleryItem[];
    similarNews: NewsItem[];
    displayMainMediaOnDetail: boolean;

	innerMedia: {[id: string]: InnerMediaItem};
	innerVotings: {[id: number]: InnerVotingItem};
	innerNews: {[key: number]: NewsItem};
	innerAds: {[key: number]: AdsItem};

	adsDisabled: boolean;
	ageRestricted: boolean;
	displayAdsBefore: boolean;
	displayAdsAfter: boolean;

	pageBlocks: PageBlock[];

	leftSideAds: AdsItem | null,
	rightSideAds: AdsItem | null,
	popupAds: AdsItem | null
}

export enum MediaType {
    IMAGE = 'IMAGE',
    VIDEO = 'VIDEO'
}

export interface InnerMediaItem {
	caption: string;
	downloadable: boolean;
	name: string;
	source: string;
	url: string;
	type: MediaType;
}

export interface InnerVotingItem {
    votingId: number;
    question: string;
    answers: VoteItemAnswer[];
	votedAnswerId: number | null;
	closed: boolean;
}

export interface VoteItem {
    votingId: number;
    question: string;
    answers: VoteItemAnswer[];
}

export interface VoteItemAnswer {
    answerId: number;
    answer: string;
    numberOfVotes?: number;
    percent?: number;
}

export type AnswerCounts = {[key: number]: number};

export enum LiveType {
    'GENERAL' = 'GENERAL',
    'TEAMSPORT' = 'TEAMSPORT'
}

export interface LiveEntry {
    title: string;
    icon: string;
    content: string;
    mediaId: number;
}

export interface NewsShortItem {
    imageUrl: string | null;
    title: string;
    slug: string;
}

export interface TitleStyle {
    value: string;
    color: string;
    backgroundColor: string;
}

export interface Topic {
    id: number;
    name: string;
    slug: string;
    backgroundColor: string;
    textColor: string;
    parentId: number;
    displayInMenu: boolean;
	externalUrl: string;
}

export interface WeatherInfo {
    temperature: number;
    icon: number;
    text: string;
}

export interface PageBlock {
	name: string;
	backgroundColor: string;
	textColor: string;
    columnConfigurations: PageBlockColumnConfiguration[];
}

export interface PageBlockContent {
    displayImage: boolean;
    displaySubtitle: boolean;
    titleSize: TemplateBlockConfigurationTitleSize;
    news: NewsItem | null;
	ads: AdsItem | null;
}

export interface PageBlockColumnConfiguration {
	width: number | null;
	contents: PageBlockContent[];
}

/*export interface PageBlockColumnConfiguration {
    displayImage: boolean;
    displaySubtitle: boolean;
    titleSize: TemplateBlockConfigurationTitleSize;
    adsSpaceId: number | null;
}*/

export enum TemplateBlockConfigurationTitleSize {
    BIG = 'BIG',
    MEDIUM = 'MEDIUM',
    SMALL = 'SMALL'
}

export interface NewsItem {
    imageUrl: string | null;
    title: string;
    slug: string;
    subTitle: string;
    publicationDate: string;
    titleStyle: string;
	topicIds: number[];
    titleStyleProcessed?: TitleStyle;
}

export interface GetNewsRequest {
    topicId: number;
    start: number;
    count: number;
    tag: string;
    search: string;
}

export type Ads = {[key: number]: AdsItem};

export interface AdsItem {
    size: string;
    adsId: number;
	kind: AdsKind;
	subKind: AdsSubKind;
    code: string;
    desktopUrl: string;
    mobileUrl: string;
    url: string;
	ftpUrl: string;
	animation: 'NONE' | 'FROM_TOP' | 'FROM_BOTTOM' | 'FROM_LEFT' | 'FROM_RIGHT' | 'ZOOM_IN' | null;
    delay: number | null;
    duration: number | null;
}

export enum AdsKind {
	OWN = 'OWN',
	OUTER_SOURCE = 'OUTER_SOURCE'
}

export enum AdsSubKind {
	IMAGE = 'IMAGE',
	VIDEO = 'VIDEO',
	HTML5 = 'HTML5',
	FTP = 'FTP'
}

export interface GetHomeResponse {
    pageBlocks: PageBlock[],
	leftSideAds: AdsItem | null,
	rightSideAds: AdsItem | null,
	popupAds: AdsItem | null
}

export interface CivisCafeItem {
	year: number;
	month: number;
	issue: string;
    pdfUrl: string;
    imageUrl: string;
}

export interface Message {
    title: string;
    text: string;
}