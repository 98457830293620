import { DomSanitizer } from "@angular/platform-browser";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "safehtml" })
export class SafeHtmlPipe implements PipeTransform {

	transform(value: string) {
		return this.sanitized.bypassSecurityTrustHtml(value);
	}

	constructor(private sanitized: DomSanitizer) { }
}