import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SETTINGS } from '../settings';

export function skipInterceptor(interceptorType: string): boolean {
    if (SETTINGS.skipInterceptors.indexOf('ALL') !== -1) {
        return true;
    }
    return SETTINGS.skipInterceptors.indexOf(interceptorType) !== -1;
}

@Injectable({
    providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (skipInterceptor('ERROR')) {
            return next.handle(request);
        }
        return next.handle(request).pipe(
            catchError((httpErrorResponse: HttpErrorResponse) => {
                if (SETTINGS.errorInterceptorCallbackfunction !== null) {
                    SETTINGS.errorInterceptorCallbackfunction(httpErrorResponse);
                }
                return throwError(httpErrorResponse);
            })
        );
    }
}
