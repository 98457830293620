import { Directive, ElementRef, inject } from "@angular/core";

@Directive({
	selector: '[scroll]'
})
export class ScrollDirective {

	direction: string = 'xy';
	previousTouchX: [number, number, number] = [0, 0, 0];
	previousTouchY: [number, number, number] = [0, 0, 0];
	previousTouchTime: [number, number, number] = [0, 0, 0];
	scrollAtT0: [number, number] = [0, 0];
	inertialTimerInterval: any | undefined = undefined;

	mousemove: (event: MouseEvent) => void = (event: MouseEvent) => { this.touchmove(event); }; 
	mouseup: (event: MouseEvent) => void = (event: MouseEvent) => { this.touchend(event); }; 
	clicke: (event: MouseEvent) => void = (event: MouseEvent) => { this.click(event); }; 

    touchstart(e: MouseEvent): void {
        if (e.button === 0) { // Check for left click
            e.preventDefault();
			(this.target.nativeElement as HTMLElement).style.cursor = 'grabbing';
            this.previousTouchX = [e.pageX, e.pageX, e.pageX];
            this.previousTouchY = [e.pageY, e.pageY, e.pageY];
            this.previousTouchTime = [Date.now() - 2, Date.now() - 1, Date.now()];
			document.addEventListener('mousemove', this.mousemove);
			document.addEventListener('mouseup', this.mouseup);
			document.addEventListener('click', this.clicke);
            if (this.inertialTimerInterval) {
                clearInterval(this.inertialTimerInterval);
                this.inertialTimerInterval = undefined;
            }
        }
    }

    touchmove(e: MouseEvent) {
        this.previousTouchX = [this.previousTouchX[1], this.previousTouchX[2], e.pageX];
        this.previousTouchY = [this.previousTouchY[1], this.previousTouchY[2], e.pageY];
        this.previousTouchTime = [this.previousTouchTime[1], this.previousTouchTime[2], Date.now()];
        if(this.direction!='y') (this.target.nativeElement as HTMLElement).scrollLeft -= this.previousTouchX[2] - this.previousTouchX[1];
        if(this.direction!='x') (this.target.nativeElement as HTMLElement).scrollTop -= this.previousTouchY[2] - this.previousTouchY[1];

        if((this.previousTouchX[2] - this.previousTouchX[1])**2+(this.previousTouchY[2] - this.previousTouchY[1])**2>25) { 
			(this.target.nativeElement as HTMLElement).classList.add('scroll');
        }
		(this.target.nativeElement as HTMLElement)
    }

    touchend(e: MouseEvent) {
		const targetElement: HTMLElement = (this.target.nativeElement as HTMLElement);
		document.removeEventListener('mousemove', this.mousemove);
		document.removeEventListener('mouseup', this.mouseup);
        (this.target.nativeElement as HTMLElement).style.cursor = '';
        this.scrollAtT0 = [targetElement.scrollLeft, targetElement.scrollTop];
		this.inertialTimerInterval = setInterval(() => this.inertialmove(), 16);
		(this.target.nativeElement as HTMLElement).classList.remove('scroll');
    }

    click(e: MouseEvent) {
		document.removeEventListener('click', this.clicke);
    }

    inertialmove() {
		const targetElement: HTMLElement = (this.target.nativeElement as HTMLElement);
		const targetWidth: number = targetElement.getBoundingClientRect().width;
		const targetHeight: number = targetElement.getBoundingClientRect().height;
        var v0X = 0, v0Y = 0;
        if(this.direction!='y') v0X = (this.previousTouchX[2] - this.previousTouchX[0]) / (this.previousTouchTime[2] - this.previousTouchTime[0])*1000/targetWidth;  // page per second    
        if(this.direction!='x') v0Y = (this.previousTouchY[2] - this.previousTouchY[0]) / (this.previousTouchTime[2] - this.previousTouchTime[0])*1000/targetHeight;  // page per second

        var av0 = this.direction=='xy'?Math.sqrt(v0X*v0X+v0Y*v0Y):(this.direction=='y'?Math.abs(v0Y):Math.abs(v0X));
        var unitVector = [v0X / av0, v0Y / av0];
        av0 = Math.min(12, Math.max(-12, 1.2*av0));
        
        var t = (Date.now() - this.previousTouchTime[2])/1000;
        var v = av0 - 14.278 * t + 75.24 * t * t / av0 - 149.72 * t * t * t / av0 / av0;
        
        if (av0 == 0 || v <= 0 || isNaN(av0)) {
            clearInterval(this.inertialTimerInterval);
            this.inertialTimerInterval = undefined;
        } else {
            var deltaX = targetWidth*unitVector[0] * (av0 * t - 7.1397 * t * t + 25.08 * t * t * t / av0 - 37.43 * t * t * t * t / av0 / av0);
            var deltaY = targetHeight*unitVector[1] * (av0 * t - 7.1397 * t * t + 25.08 * t * t * t / av0 - 37.43 * t * t * t * t / av0 / av0);
            let maxScroll = [targetElement.scrollWidth - targetWidth, targetElement.scrollHeight - targetHeight];
            let newScroll = [Math.min(maxScroll[0],Math.max(0,this.scrollAtT0[0] - deltaX)), Math.min(maxScroll[1],Math.max(0,this.scrollAtT0[1] - deltaY))];
            
            if ((newScroll[0]==0 || newScroll[0]==maxScroll[0]) && (newScroll[1]==0 || newScroll[1]==maxScroll[1]))  {
                clearInterval(this.inertialTimerInterval);
                this.inertialTimerInterval = undefined;
            }
            if(this.direction!='y')
                targetElement.scrollLeft = newScroll[0];
            if(this.direction!='x')
			targetElement.scrollTop = newScroll[1];
        }
    }

    constructor(
		private target: ElementRef
	) {
		(this.target.nativeElement as HTMLElement).addEventListener('mousedown', (e: MouseEvent) => {
			this.touchstart(e);
		});
    }
}