import { Inject, inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { NEWS_DETAILS_B, NEWS_DETAILS_C, NEWS_DETAILS_SZ } from './consts';
import { getPageFromActivatedRouteSnapshot, getQueryFromActivatedRouteSnapshot } from './helper';
import { Ads, CivisCafeItem, GetHomeResponse, NewsDetail, NewsItem, Topic } from './interfaces';
import { SITE_ID } from './tokens';

@Injectable({ providedIn: 'root' })
export class HomeResolve implements Resolve<GetHomeResponse> {
    constructor(private apiService: ApiService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<GetHomeResponse> {
        const preview: string = route.queryParamMap.get('preview');
        return this.apiService.getHome(preview);
    }
}

@Injectable({ providedIn: 'root' })
export class NewsDetailResolve implements Resolve<NewsDetail> {
    constructor(private apiService: ApiService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<NewsDetail> {
        const slug: string = route.paramMap.get('slug');
        return this.apiService.getNewsDetail(slug, route.queryParamMap.get('previewguid')).pipe(map(({newsDetail}) => newsDetail));
    }
}

@Injectable({providedIn: 'root'})
export class SearchNewsResolve implements Resolve<NewsItem[]> {
    constructor(private apiService: ApiService) {}
    resolve(activatedRouteSnapshot: ActivatedRouteSnapshot): Observable<NewsItem[]> {
        const query: string = getQueryFromActivatedRouteSnapshot(activatedRouteSnapshot);
        const page: number = getPageFromActivatedRouteSnapshot(activatedRouteSnapshot);
        return this.apiService.getNews({
            topicId: null,
            count: 10,
            start: (page - 1) * 10,
            tag: null,
            search: query
        });
    }
}

@Injectable({providedIn: 'root'})
export class TopicNewsResolve implements Resolve<NewsItem[]> {
    constructor(private apiService: ApiService) {}
    resolve(activatedRouteSnapshot: ActivatedRouteSnapshot): Observable<NewsItem[]> {
        const topic: Topic = activatedRouteSnapshot.data.topic;
        const page: number = getPageFromActivatedRouteSnapshot(activatedRouteSnapshot);
        return this.apiService.getNews({
            topicId: topic.id,
            count: 20,
            start: (page - 1) * 20,
            tag: null,
            search: null
        });
    }
}

@Injectable({providedIn: 'root'})
export class TagNewsResolve implements Resolve<NewsItem[]> {
    constructor(private apiService: ApiService) {}
    resolve(activatedRouteSnapshot: ActivatedRouteSnapshot): Observable<NewsItem[]> {
        const page: number = getPageFromActivatedRouteSnapshot(activatedRouteSnapshot);
        return this.apiService.getNews({
            count: 20,
            start: (page - 1) * 20,
            topicId: null,
            tag: activatedRouteSnapshot.paramMap.get('tag'),
            search: null
        });
    }
}

@Injectable({providedIn: 'root'})
export class NewsDetailsAdsResolve implements Resolve<Ads> {
	adsIds: number[] = NEWS_DETAILS_C;
    constructor(
		private apiService: ApiService,
		@Inject(SITE_ID) private siteId: number
	) {
		if (this.siteId === 2) {
			this.adsIds = NEWS_DETAILS_B;
		}
		if (this.siteId === 3) {
			this.adsIds = NEWS_DETAILS_SZ;
		}
	}
    resolve(): Observable<Ads> {
        return this.apiService.getAds(this.adsIds).pipe(map(({ads}) => ads));
    }
}

@Injectable({providedIn: 'root'})
export class CivisCafeResolve implements Resolve<CivisCafeItem[]> {
    constructor(private apiService: ApiService) {}
    resolve(): Observable<CivisCafeItem[]> {
        return this.apiService.getCiviscafe().pipe(map(({items}) => items));
    }
}
