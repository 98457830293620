import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { HOST } from "src/app/tokens";

@Component({
	templateUrl: './impressum.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImpressumComponent implements OnInit {

	ngOnInit(): void {
		this.cdr.detectChanges();
	}

	constructor(
		private cdr: ChangeDetectorRef,
		@Inject(HOST) public host: string
	) {
		this.cdr.detach();
	}

}