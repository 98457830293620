import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { ActivatedRoute, ActivationEnd, Router } from '@angular/router';
import { Ads, AdsItem, AdsKind, AdsSubKind, InnerMediaItem, InnerVotingItem, LiveType, MediaType, NewsDetail, NewsDetailGalleryItem, NewsEditorContentBlock, NewsEditorContentBlockType, NewsItem, NewsType, TemplateBlockConfigurationTitleSize, Topic, VoteItemAnswer } from '../../interfaces';
import { InitService } from '../../init.service';
import { replaceAll } from 'src/app/helper';
import { UnsubscribeComponent } from '../unsubscribe.component';
import { takeUntil } from 'rxjs/operators';
import { DomSanitizer, Meta, SafeHtml, SafeResourceUrl, Title } from '@angular/platform-browser';
import { ApiService } from 'src/app/api.service';
import { LightGallerySettings } from 'lightgallery/lg-settings';
import lightGallery from 'lightgallery';
import { timer } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { NEWS_DETAILS_B, NEWS_DETAILS_C, NEWS_DETAILS_SZ } from 'src/app/consts';
import { GoogleAnalyticsFunction, GOOGLE_ANALYTICS, LOCATION, POPULAR_WIDGET_ID, RELATED_WIDGET_ID, SITE_ID } from 'src/app/tokens';

@Component({
	selector: 'app-newsdetail',
	templateUrl: './newsdetail.component.html',
	styleUrls: ['./newsdetail.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewsdetailComponent extends UnsubscribeComponent implements OnInit {

	@ViewChild('newsDetailContentDiv') newsDetailContentDiv: ElementRef<HTMLDivElement> | null;

	TemplateBlockConfigurationTitleSize = TemplateBlockConfigurationTitleSize;
	NewsType = NewsType;
	LiveType = LiveType;

	newsDetail: NewsDetail = null;
	topics: Topic[] = [];

	ads: Ads = {};

	lgsettings: LightGallerySettings = {
		counter: false,
		index: 0
	};

	innerMedia: { [key: string]: InnerMediaItem } = {};
	innerVotings: { [key: string]: InnerVotingItem } = {};
	innerNews: { [key: string]: NewsItem } = {};
	innerAds: {[key: number]: AdsItem} = {};

	MediaType = MediaType;
	NewsEditorContentBlockType = NewsEditorContentBlockType;

	galleryImageIndex: number = 0;

	leftSideAds: AdsItem | null = null;
	rightSideAds: AdsItem | null = null;
	popupAds: AdsItem | null = null;
	popupAdVisible: boolean = false;

	apiBaseUrl: string = '';

	firstTextBlockIndex: number | null = null;

	clicked(event: MouseEvent): void {

		const eventTargets: EventTarget[] = event.composedPath();
		let href: string | null = null;
		for (let eventTarget of eventTargets) {
			if ((eventTarget as HTMLElement).nodeName.toLowerCase() === 'a') {
				href = (eventTarget as HTMLAnchorElement).href;
			}
			if ((eventTarget as HTMLElement).classList.contains('editor-block')) {
				if (href !== null) {
					window.open(href, '_blank');
					event.preventDefault();
					event.stopPropagation();
				}
				break;
			}
		}
	}

	getAds(adsId: number): AdsItem | null {
		if (this.siteId === 2) {
			adsId = NEWS_DETAILS_B[NEWS_DETAILS_C.indexOf(adsId)];
		}
		if (this.siteId === 3) {
			adsId = NEWS_DETAILS_SZ[NEWS_DETAILS_C.indexOf(adsId)];
		}
		return this.ads[adsId];
	}

	hidePopupAd(): void {
		this.popupAdVisible = false;
		this.cookieService.set('civispopupaddetail', '1', 1, '/');
		this.cdr.detectChanges();
	}

	get galleryMain(): NewsDetailGalleryItem | null {
		return this.newsDetail.gallery.length > 0 ? this.newsDetail.gallery[this.galleryImageIndex] : null;
	}

	galleryNext(): void {
		this.galleryImageIndex++;
		if (this.galleryImageIndex > this.newsDetail.gallery.length - 1) {
			this.galleryImageIndex = 0;
		}
		this.lgsettings.index = this.galleryImageIndex;
		this.cdr.detectChanges();
	}

	galleryPrev(): void {
		this.galleryImageIndex--;
		if (this.galleryImageIndex < 0) {
			this.galleryImageIndex = this.newsDetail.gallery.length - 1;
		}
		this.lgsettings.index = this.galleryImageIndex;
		this.cdr.detectChanges();
	}

	imageClicked(index: number): void {
		this.galleryImageIndex = index;
		this.lgsettings.index = this.galleryImageIndex;
		this.cdr.detectChanges();
	}

	openLightGallery(): void {
		const div: HTMLDivElement = this.document.createElement('div');
		for (let i = 0; i < this.newsDetail.gallery.length; i++) {
			const a: HTMLAnchorElement = this.document.createElement('a');
			a.href = this.newsDetail.gallery[i].url;
			div.appendChild(a);
		}
		const l = lightGallery(div, {
			counter: false
		})
		l.openGallery(this.galleryImageIndex);
	}

	get gallerySub(): NewsDetailGalleryItem[] {
		if (this.newsDetail.gallery.length > 1) {
			return this.newsDetail.gallery.slice(1, this.newsDetail.gallery.length - 1);
		}
		return [];
	}
	
	updateNewsDetails(): void {
		if (this.newsDetail === null) {
			return;
		}
		this.leftSideAds = this.newsDetail.leftSideAds;
		this.rightSideAds = this.newsDetail.rightSideAds;
		if (isPlatformBrowser(this.platformId)) {
			this.popupAdVisible = !this.cookieService.check('civispopupaddetail');
			if (this.initService.floatingAds !== null) {
				this.popupAdVisible = false;
			}
			if (this.popupAdVisible) {
				if (this.newsDetail.popupAds !== null) {
					timer((this.newsDetail.popupAds.delay || 0) * 1000).subscribe(() => {
						this.popupAds = this.newsDetail.popupAds;
						this.cdr.detectChanges();
						if (this.newsDetail.popupAds.duration !== null) {
							timer(this.newsDetail.popupAds.duration * 1000).subscribe(() => {
								this.hidePopupAd();
							});
						}
					});
				}
			}
		}
		this.innerMedia = this.newsDetail.innerMedia;
		this.innerVotings = this.newsDetail.innerVotings;
		Object.keys(this.innerVotings).forEach((key: string) => {
			if (this.innerVotings[key].closed || this.innerVotings[key].votedAnswerId !== null) {
				const fullCount: number = this.innerVotings[key].answers.map((a) => a.numberOfVotes).reduce((a, b) => a + b, 0);
				this.innerVotings[key].answers.forEach((answer) => {
					if (answer.numberOfVotes === 0) {
						answer.percent = 0;
					} else {
						answer.percent = Math.round((answer.numberOfVotes / fullCount) * 100);
					}
				});
			}
		});
		this.innerNews = this.newsDetail.innerNews;
		this.innerAds = this.newsDetail.innerAds;

		this.newsDetail.content.forEach((newsEditorContentBlock: NewsEditorContentBlock) => {
			if (newsEditorContentBlock.type === NewsEditorContentBlockType.EDITOR) {
				let data: string = newsEditorContentBlock.data;
				data = replaceAll(data, '<p><br></p>', '');
				data = replaceAll(data, '<p><br/></p>', '');
				data = replaceAll(data, '<p><br /></p>', '');
				newsEditorContentBlock.data = data;
			}
		});

		this.firstTextBlockIndex = this.newsDetail.content.findIndex(({ type }) => type === NewsEditorContentBlockType.EDITOR);

		this.topics = this.initService.getTopicsByIds(this.newsDetail.topicIds);
		this.title.setTitle(this.newsDetail.title);
		/*
		if ((window as any).instgrm !== undefined) {
			(window as any).instgrm.Embeds.process();
		}
		if ((window as any).twttr !== undefined) {
			(window as any).twttr.widgets.load()
		}*/

		// META TAGS
		this.meta.removeTag('name=title');
		this.meta.removeTag('name=description');
		this.meta.removeTag('name=keywords');
		this.meta.removeTag('name=\'og:image\'');
		this.meta.removeTag('name=\'og:description\'');

		const createMetaElement: (name: string, content: string) => void = (name: string, content: string): void => {
			const element: HTMLMetaElement = this.document.createElement('meta');
			element.setAttribute('property', name);
			element.content = content;
			if (this.document.head.firstChild !== null) {
				this.document.head.appendChild(element);
			}
		}

		createMetaElement('title', this.newsDetail.title);
		createMetaElement('description', this.newsDetail.subtitle);
		createMetaElement('og:description', this.newsDetail.subtitle);

		if (this.newsDetail !== null && this.newsDetail.mainMedia !== null) {
			createMetaElement('og:image', this.newsDetail.mainMedia.url);
		}

		this.googleAnalytics('event', 'page_view', {
			page_location: this.location,
			page_title: this.newsDetail.title
		});

		timer(200).subscribe(() => {
			const script: HTMLScriptElement = this.document.createElement('script');
			script.async = true;
			script.src = 'https://related.hu/js/v2/widget.js';
			this.document.head.appendChild(script);
		});

		/*timer(200).subscribe(() => {
			const script: HTMLScriptElement = this.document.createElement('script');
			script.async = true;
			script.src = 'https://cfusionsys.com/spa.js';
			this.document.head.appendChild(script);
		});*/

		this.cdr.detectChanges();
	}

	vote(voting: InnerVotingItem, answerId: number): void {
		if (voting.votedAnswerId || voting.closed) {
			return;
		}
		voting.votedAnswerId = answerId;
		this.las.answer(voting.votingId, answerId).subscribe(({ answerCounts }) => {
			const keys: string[] = Object.keys(answerCounts);
			const fullCount: number = keys.map((key: string) => answerCounts[key]).reduce((a, b) => a + b, 0);
			keys.forEach((votingId: string) => {
				const answerIdNumber: number = parseInt(votingId, 10);
				const answer: VoteItemAnswer = voting.answers.find((a) => a.answerId === answerIdNumber);
				if (answer !== null) {
					answer.numberOfVotes = answerCounts[answerIdNumber];
					answer.percent = Math.round((answerCounts[answerIdNumber] / fullCount) * 100);
				}
			})
			this.cdr.detectChanges();
		});
	}

	convertToTable(header: string, data: string): SafeHtml {

		const finalTableData: string[][] = [];
		const dataLines: string[] = data.split(/\r?\n/g);
		dataLines.forEach((dataLine: string) => {
			if (dataLine.length > 0) {
				finalTableData.push(dataLine.split(/\t/g));
			}
		});

		const maxCols: number = Math.max(...finalTableData.map(f => f.length));

		let tableData: string = '<table class="table-data"><tbody>';
		tableData += '<tr><td colspan="999" style="font-weight: bold; font-size: 20px;">' + header + '</td></tr>';
		for (let i = 0; i < finalTableData.length; i++) {
			tableData += '<tr>';
			for (let j = 0; j < maxCols; j++) {
				if (finalTableData[i] !== undefined && finalTableData[i][j] !== undefined) {
					tableData += '<td>' + finalTableData[i][j] + '</td>';
				} else {
					tableData += '<td></td>';
				}
			}
			tableData += '</tr>';
		}
		tableData += '</tbody></table>';

		return this.domSanitizer.bypassSecurityTrustHtml(tableData);
	}

	getSafeYoutubeUrl(src: string): SafeResourceUrl {
		if (src.startsWith('https://www.youtube.com/shorts/') || src.startsWith('https://www.youtube.com/watch?v=')) {
			const videoId: string = src.replace('https://www.youtube.com/shorts/', '').replace('https://www.youtube.com/watch?v=', '');
			return this.domSanitizer.bypassSecurityTrustResourceUrl('https://youtube.com/embed/' + videoId);
		}
		return this.domSanitizer.bypassSecurityTrustResourceUrl(src);
	}

	acceptAgeRestriction(): void {
		this.newsDetail.ageRestricted = false;
		this.cdr.detectChanges();
	}

	declineAgeRestriction(): void {
		this.router.navigate(['/']);
	}

	loadScripts(): void {
		const hasTiktok: boolean = this.newsDetail.content.some(({ type }) => type === NewsEditorContentBlockType.TIKTOK);
		if (hasTiktok) {
			this.loadScript(NewsEditorContentBlockType.TIKTOK);
		}
		const hasTwitter: boolean = this.newsDetail.content.some(({ type }) => type === NewsEditorContentBlockType.TWITTER);
		if (hasTwitter) {
			this.loadScript(NewsEditorContentBlockType.TWITTER);
		}
		const hasInstagram: boolean = this.newsDetail.content.some(({ type }) => type === NewsEditorContentBlockType.INSTAGRAM);
		if (hasInstagram) {
			this.loadScript(NewsEditorContentBlockType.INSTAGRAM);
		}
	}

	loadScript(newsEditorContentBlockType: NewsEditorContentBlockType): void {
		timer(200).subscribe(() => {
			const script: HTMLScriptElement = this.document.createElement('script');
			script.async = true;
			switch(newsEditorContentBlockType) {
				case NewsEditorContentBlockType.TIKTOK:
					script.src = 'https://www.tiktok.com/embed.js';
					this.document.head.appendChild(script);
					break;
				case NewsEditorContentBlockType.TWITTER:
					script.src = 'https://platform.twitter.com/widgets.js';
					this.document.head.appendChild(script);
					break;
				case NewsEditorContentBlockType.INSTAGRAM:
					script.src = 'https://instagram.com/embed.js';
					this.document.head.appendChild(script);
					if ((window as any).instgrm !== undefined) {
						(window as any).instgrm.Embeds.process();
					}
					break;
			}
		});
	}

	copyUr(): void {
		/*navigator.clipboard.writeText(location.href).then(() => {

		});*/
	}

	sendEmail(): void {
		const a: HTMLAnchorElement = this.document.createElement('a');
		const body: string = `
			${location.href}
		`;
		a.href = 'mailto:?subject=' + this.newsDetail.title + '&body=' + body;
		a.click();
	}

	ngOnInit(): void {
		this.router.events.pipe(takeUntil(this.unsubscribe)).subscribe((event) => {
			if (event instanceof ActivationEnd) {
				this.newsDetail = event.snapshot.data.newsDetail;
				this.updateNewsDetails();
				this.loadScripts();
			}
		});
		this.newsDetail = this.activatedRoute.snapshot.data.newsDetail;
		this.updateNewsDetails();
	}

	constructor(
		private router: Router,
		private initService: InitService,
		private cdr: ChangeDetectorRef,
		private activatedRoute: ActivatedRoute,
		private title: Title,
		private domSanitizer: DomSanitizer,
		private las: ApiService,
		private meta: Meta,
		private cookieService: CookieService,
		@Inject(DOCUMENT) private document: Document,
		@Inject(PLATFORM_ID) private platformId: Object,
		@Inject(SITE_ID) private siteId: number,
		@Inject(GOOGLE_ANALYTICS) private googleAnalytics: GoogleAnalyticsFunction,
		@Inject(LOCATION) private location: string,
		@Inject(RELATED_WIDGET_ID) public relatedWidgetId: string,
		@Inject(POPULAR_WIDGET_ID) public popularWidgetId: string
	) {
		super();
		this.apiBaseUrl = this.las.apiBaseUrl;
		this.ads = this.activatedRoute.snapshot.data.ads;
		this.cdr.detach();
	}
}
