import { DOCUMENT } from "@angular/common";
import { inject, InjectionToken } from "@angular/core";

export const ACCEPTED_HOSTS: InjectionToken<string[]> = new InjectionToken('Accepted Hosts', {
	factory: () => [
		'civishir.hu', 'borsodihir.hu', 'szabolcsihir.hu',
		'civishir.codefox.hu'
	]
});

export const DEFAULT_HOST: InjectionToken<string> = new InjectionToken('Default Host', {
	factory: () => inject(ACCEPTED_HOSTS)[0]
});

export const PAGE_TITLE: InjectionToken<string> = new InjectionToken('Page Title', {
	factory: () => [
		'Civishír', 'Borsodihír', 'Szabolcsihír',
		'Civishír DEV'
	][inject(SITE_ID) - 1]
});

export const GOOGLE_ANALYTICS_TRACKING_CODE: InjectionToken<string> = new InjectionToken('Google Analytics Tracking Code', {
	factory: () => ['G-MMTF8Y2DZZ', 'G-B36SNW6JQX', 'G-VWHMXFZPBG', 'G-MMTF8Y2DZZ'][inject(SITE_ID) - 1]
});

export const LOCAL_STORE_KEY: InjectionToken<string> = new InjectionToken('Local Storage Key', {
	factory: () => ['civis_local_accesstoken', 'borsod_local_accesstoken', 'szabolcs_local_accesstoken', 'civis_dev_local_accesstoken'][inject(SITE_ID) - 1]
});

export const RELATED_WIDGET_ID: InjectionToken<string> = new InjectionToken('Related Widget Id', {
	factory: () => ['2927641', '2438352', '5890212', '2927641'][inject(SITE_ID) - 1]
});

export const POPULAR_WIDGET_ID: InjectionToken<string> = new InjectionToken('Popular Widget Id', {
	factory: () => ['civishir_native_sm_1102', 'borsodihir_native_nw', 'szabolcsihir_native_nw', 'civishir_native_sm_1102'][inject(SITE_ID) - 1]
})

export const HOST: InjectionToken<string> = new InjectionToken('Site Host', {
	factory: () => {
		const document: Document = inject(DOCUMENT);
		let host: string = document.location.host.toLowerCase();
		if (host.startsWith('www.')) {
			host = host.slice(4);
		}
		if (inject(ACCEPTED_HOSTS).includes(host)) {
			return host;
		}
		return inject(DEFAULT_HOST);
	}
});

export const LOCATION: InjectionToken<string> = new InjectionToken('Location', {
	factory: () => {
		const document: Document = inject(DOCUMENT);
		if (document.defaultView !== null) {
			return document.defaultView.location.href;
		}
		return '';
	}
})

export const SITE_ID: InjectionToken<number> = new InjectionToken('Site Id', {
	factory: () => {
		const host: string = inject(HOST);
		return inject(ACCEPTED_HOSTS).indexOf(host) + 1;
	}
});

export type GoogleAnalyticsFunction = (type: 'event', event: 'page_view', params: {
	page_title?: string,
	page_location?: string
}) => void;

export const GOOGLE_ANALYTICS_DEFAULT: GoogleAnalyticsFunction = () => {};

export const GOOGLE_ANALYTICS: InjectionToken<GoogleAnalyticsFunction> = new InjectionToken('Google Analytics', {
	factory: () => {
		const document: Document = inject(DOCUMENT);
		if (document.defaultView !== null) {
			return document.defaultView.window['gtag'] || GOOGLE_ANALYTICS_DEFAULT;
		}
		return GOOGLE_ANALYTICS_DEFAULT;
	}
});