import { DomSanitizer } from "@angular/platform-browser";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "saferesourceurl" })
export class SafeResourceUrlPipe implements PipeTransform {

	transform(value: string) {
		return this.sanitized.bypassSecurityTrustResourceUrl(value);
	}

	constructor(private sanitized: DomSanitizer) { }
}