import { isMobile } from "./helper";
import { TitleStyle } from "./interfaces";

export const TITLE_STYLES: TitleStyle[] = [
    { value: 'DEFAULT', color: null, backgroundColor: null },
    { value: 'RED', color: '#C60800', backgroundColor: null }
];

export const IS_MOBILE: boolean = isMobile();


export const NEWS_DETAILS_C: number[] = [1, 2, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 371, 372, 373, 380, 381, 382, 392, 393, 394, 395, 396];
export const NEWS_DETAILS_SZ: number[] = [117, 118, 196, 197, 198, 199, 200, 201, 202, 203, 204, 205, 206, 377, 378, 379, 386, 387, 388, 397, 398, 399, 400, 401];
export const NEWS_DETAILS_B: number[] = [244, 245, 323, 324, 325, 326, 327, 328, 329, 330, 331, 332, 333, 374, 375, 376, 383, 384, 385, 402, 403, 404, 405, 406];
