import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit, PLATFORM_ID, ViewChild, Inject, HostBinding } from '@angular/core';
import { timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from 'src/app/api.service';
import { IS_MOBILE } from 'src/app/consts';
import { AdsItem, AdsKind, AdsSubKind } from 'src/app/interfaces';
import { UnsubscribeComponent } from '../../unsubscribe.component';

@Component({
	selector: 'app-ads',
	templateUrl: './ads.component.html',
	styleUrls: ['./ads.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdsComponent extends UnsubscribeComponent implements OnInit, AfterViewInit {

	AdsKind = AdsKind;
	AdsSubKind = AdsSubKind;

	width: string = 'auto';
	widthNumber: number = 0;
	height: number = 0;

	isMobile: boolean = IS_MOBILE;

	@Input('ads') ads: AdsItem = null;
	@Input('block') block: boolean = false;
	@Input('autoSize') autoSize: boolean = false;

	@HostBinding('class') get classes(): string {
		return this.block ? 'block' : '';
	}

	@ViewChild('adholder') adholder: ElementRef<HTMLDivElement> | undefined = undefined;

	get url(): string | null {
		if (this.ads === null) {
			return null;
		}
		if (this.isMobile && this.ads.mobileUrl !== '') {
			return this.ads.mobileUrl;
		}
		return this.ads.desktopUrl;
	}

	adsClick(): void {
		this.apiService.adsClick(this.ads.adsId).pipe(takeUntil(this.unsubscribe)).subscribe(() => {

		});
	}

	ngAfterViewInit(): void {
		if (this.ads !== null && (this.ads.kind === AdsKind.OUTER_SOURCE || (this.ads.kind === AdsKind.OWN && this.ads.subKind === AdsSubKind.FTP))) {
			if (isPlatformBrowser(this.platformId)) {
				timer(100).subscribe(() => {
					if (this.adholder !== undefined) {
						this.adholder.nativeElement.innerHTML = this.ads.code;
						timer(100).subscribe(() => {
							if (this.adholder !== undefined) {
								const containerWidth: number = this.adholder.nativeElement.getBoundingClientRect().width;
								const iframe = this.adholder.nativeElement.querySelector('iframe');
								if (iframe !== null) {
									const iframeWidth: number = iframe.getBoundingClientRect().width;
									const iframeheight: number = iframe.getBoundingClientRect().height;
									if (iframeWidth > containerWidth) {
										const ratio: number = containerWidth / iframeWidth;
										iframe.style.transform = 'scale(' + ratio + ')';
										iframe.style.transformOrigin = '0 0';
										this.adholder.nativeElement.style.height = (iframeheight * ratio).toString() + 'px';
									}
								}
							}
						});
					}
				});
			}
		}
	}

	ngOnInit(): void {
		const sizes: number[] = this.ads.size.toLowerCase().split('x').map((value: string) => parseInt(value, 10));
		this.width = this.autoSize ? 'auto' : sizes[0] + 'px';
        this.widthNumber = sizes[0];
		this.height = sizes[1];
		this.cdr.detectChanges();
	}

	constructor(
		private apiService: ApiService,
		private cdr: ChangeDetectorRef,
		@Inject(PLATFORM_ID) private platformId: Object
	) {
		super();
		this.cdr.detach();
	}

}
