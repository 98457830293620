import { isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { InitService } from 'src/app/init.service';
import { NewsShortItem } from 'src/app/interfaces';

@Component({
	selector: 'app-freshnews',
	templateUrl: './freshnews.component.html',
	styleUrls: ['./freshnews.component.scss']
})
export class FreshnewsComponent implements OnInit {

	latestNews: NewsShortItem[] = [];
	activeNewsIndex: number = 0;

	animation: Subscription | null = null;

	prev(): void {
		this.activeNewsIndex--;
		if (this.activeNewsIndex < 0) {
			this.activeNewsIndex = this.latestNews.length - 1;
		}
	}

	next(): void {
		this.activeNewsIndex++;
		if (this.activeNewsIndex > this.latestNews.length - 1) {
			this.activeNewsIndex = 0;
		}
	}

	@HostListener('mouseleave')
	startAnimation(): void {
		if (this.animation !== null) {
			this.stopAnimation();
		}
		if (isPlatformBrowser(this.platformId)) {
			this.animation = interval(5000).subscribe(() => {
				this.next();
			});
		}
	}

	@HostListener('mouseenter')
	stopAnimation(): void {
		if (this.animation === null) {
			return;
		}
		this.animation.unsubscribe();
		this.animation = null;
	}

	ngOnInit(): void {
	}

	constructor(
		private initService: InitService,
		@Inject(PLATFORM_ID) private platformId: Object,
	) {
		this.latestNews = this.initService.latestNews;
		this.startAnimation();
	}

}
