<div id="footer">
    <div class="container">
        <div class="footer-block">
            <div class="footer-block-title neuehaasdisplay-semibold">Legolvasottabb</div>
            <div id="footer-news-list">
                <a [routerLink]="['/hir', popularNewsItem.slug]" [title]="popularNewsItem.title" class="footer-news cb" *ngFor="let popularNewsItem of popularNews">
                    <div class="footer-news-image-holder image-holder" *ngIf="popularNewsItem.imageUrl !== null">
                        <img [src]="popularNewsItem.imageUrl + '?size=120'" [alt]="popularNewsItem.title" />
                    </div>
                    <div class="footer-news-title">{{popularNewsItem.title}}</div>
                </a>
            </div>
        </div>
        <div class="footer-block">
            <div class="footer-block-title neuehaasdisplay-semibold">Kiemelt</div>
            <div id="footer-news-list">
                <a [routerLink]="['/hir', featuredNewsItem.slug]" [title]="featuredNewsItem.title" class="footer-news cb" *ngFor="let featuredNewsItem of featuredNews">
                    <div class="footer-news-image-holder image-holder" *ngIf="featuredNewsItem.imageUrl !== null">
                        <img [src]="featuredNewsItem.imageUrl + '?size=120'" [alt]="featuredNewsItem.title" />
                    </div>
                    <div class="footer-news-title">{{featuredNewsItem.title}} </div>
                </a>
            </div>
        </div>
        <div class="footer-block">
            <div class="footer-block-title neuehaasdisplay-semibold">Népszerű témák</div>
            <div id="footer-tags">
                <a [routerLink]="['/cimke', popularTag]" class="footer-tag" *ngFor="let popularTag of popularTags">{{popularTag}}</a>
            </div>
        </div>
    </div>
</div>
<div id="bottom">
    <div class="container cb">
        <div id="bottom-left">
            <a routerLink="/">{{host}} - Minden jog fenntartva! (2023)</a>
        </div>
        <div id="bottom-right">
            <a [routerLink]="['/impresszum']">Impresszum</a>
            <a [href]="'assets/documents/' + host.replace('.', '-') + '-jogi-nyilatkozat.pdf'" target="_blank">Jogi nyilatkozat</a>
            <a [href]="'assets/documents/' + host.replace('.', '-') + '-adatkezelesi-tajekoztato.pdf'" target="_blank">Adatvédelem</a>
        </div>
    </div>
</div>