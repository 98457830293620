<div class="question">
	<div class="question-mark">?</div>
	<div class="question-text">{{voteItem.question}}</div>
</div>
<div class="answers">
	<div class="answer" *ngFor="let answer of voteItem.answers" (click)="makeAnswer(answer.answerId)">
		<div class="answer-checkbox" [ngClass]="{'voted': voted === answer.answerId}">
			<img src="assets/images/check.svg" />
		</div>
		<div class="answer-data">
			<div class="answer-title-line">
				<span class="answer-title">{{answer.answer}}</span>
				<span class="answer-value" *ngIf="voted">{{answer.percent}}%</span>
			</div>
			<div class="answer-bg" *ngIf="voted">
				<div class="answer-bg-inner" [ngStyle]="{'width.%': answer.percent}"></div>
			</div>
		</div>
	</div>
</div>