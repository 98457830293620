
<div id="similar-news-title">Kapcsolódó</div>

<a [routerLink]="['/hir', newsItem.slug]" [title]="newsItem.title" id="similar-news-data">
	<div id="similar-news-data-image">
		<img [src]="newsItem.imageUrl" />
	</div>
	<div id="similar-news-data-holder">
		<div id="similar-news-data-title">{{newsItem.title}}</div>
		<div id="similar-news-data-subtitle">{{newsItem.subTitle}}</div>
	</div>
</a>