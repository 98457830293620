import { ChangeDetectorRef, Component, ElementRef, Inject, inject, ViewChild } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { AdsItem, Topic, WeatherInfo } from 'src/app/interfaces';
import { SITE_ID } from 'src/app/tokens';
import { getMenuTopics, getTopicsByParent } from '../../helper';
import { InitService } from '../../init.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement> = null;
  search: boolean = false;

  topics: Topic[] = [];
  activeTopicId: number = null;

  headerAds: AdsItem | null = null;

  topTags: string[] = ['Koronavírus', 'Washington 2021'];
  today: string = null;
  weatherInfo: WeatherInfo = null;
  nameday: string = 'Melánia';
  logo: string = 'civis';

  mobileMenuActive: boolean = false;
  mobileMenuActiveTopicId: number = null;

  constructor(
    private initService: InitService,
    private router: Router,
    private cdr: ChangeDetectorRef,
	@Inject(SITE_ID) private siteId: number
  ) {
	this.headerAds = this.initService.headerAds;
    this.topics = getMenuTopics(this.initService.topics);
    this.topTags = this.initService.topTags;
    this.today = this.initService.today;
    this.weatherInfo = this.initService.weatherInfo;
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.activeTopicId = null;
        this.search = false;
        if (this.searchInput !== null) {
          this.searchInput.nativeElement.value = '';
        }
        this.hideMobileMenu();
      }
    });
	if (this.siteId === 2) {
		this.logo = 'borsod';
	}
	if (this.siteId === 3) {
		this.logo = 'szabolcs';
	}
  }

  toggleSearch(): void {
    this.search = !this.search;
    if (this.search) {
      this.cdr.detectChanges();
      this.searchInput.nativeElement.focus();
    }
  }

  getTopicsByParent(parentId: number): Topic[] {
    return getTopicsByParent(this.initService.topics, parentId);
  }

  toggleMobileMenu(): void {
    this.mobileMenuActive = !this.mobileMenuActive;
    this.cdr.detectChanges();
  }

  hideMobileMenu(): void {
    this.mobileMenuActive = false;
    this.cdr.detectChanges();
  }

  topicMouseover(topicId: number): void {
    this.activeTopicId = topicId;
  }

  topicMouseleave(): void {
    this.activeTopicId = null;
  }

  closeMobileMenu(): void {
    this.mobileMenuActive = false;
  }

  showMobileMenuTopic(topicId: number): void {
    if (topicId === this.mobileMenuActiveTopicId) {
      this.mobileMenuActiveTopicId = null;
    } else {
      this.mobileMenuActiveTopicId = topicId;
    }
    this.cdr.detectChanges();
  }

  ee(event: MouseEvent): void {
    if (event.ctrlKey && event.shiftKey && this.today.toLowerCase().endsWith('mihály') || this.today.toLowerCase().endsWith('károly')) {
      this.today = 'Devgod day';
    }
  }

  getWeatherInfoIcon(icon: number): string {
    return [
      'A',
      '1', '1', '2', '2', '2', '3', '3', '3', '3', '3',
      'Z', 'O', 'N', 'N', 'Q', 'R', 'S', 'T', 'U', 'A',
      'A', 'A', '3', 'A', 'A', '3', 'U', 'U', 'U', '2',
      'A', 'A', '"', '6', 'a', 'a', 'a', '6', 'f', 'f',
      'f', 'n', 'o', 'q'
    ][icon - 1] || 'A';
  }

  keyup(keyboardEvent: KeyboardEvent): void {
    const query: string = (keyboardEvent.target as HTMLInputElement).value;
    if (keyboardEvent.key.toLowerCase() === 'enter' && query.length >= 4) {
      this.router.navigate(['/kereses', query]);
    }
  }

}
