import { HttpErrorResponse } from "@angular/common/http";

interface Settings {
    errorInterceptorCallbackfunction: (httpErrorResponse: HttpErrorResponse) => void;
    skipInterceptors: string[];
	apiBaseUrl: string;
}

export let SETTINGS: Settings = {
    errorInterceptorCallbackfunction: null,
	skipInterceptors: [],
	apiBaseUrl: "https://civishir.hu:10013",
	//apiBaseUrl: "https://civishir.codefox.hu:10011",
};