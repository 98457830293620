<div class="container">
	<h1>Keresés: {{query}}</h1>
	<div class="news-list">
		<app-news
			[news]="newsItem"
			[displayImage]="true"
			[titleSize]="TemplateBlockConfigurationTitleSize.MEDIUM"
			[displaySubtitle]="true"
			[displayImageOnLeft]="true"
			*ngFor="let newsItem of news"
		></app-news>
	</div>
	<div class="load-more" (click)="nextPage()">Továbbiak betöltése</div>
</div>