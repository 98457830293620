import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { localStorage, sessionStorage } from '../helper';

export enum TokenServiceMode {
    LOCALSTORAGE = 0,
    SESSIONSTORAGE = 1,
    COOKIE = 2
}

@Injectable({
    providedIn: 'root'
})
export class TokenService {

	localStorage: Storage | undefined = localStorage();
	sessionStorage: Storage | undefined = sessionStorage();

    private _cookiePath: string = null;
    private _accessToken: string = null;
    private _accessTokenKey = 'acccessToken';
    private _accessTokenCookieExpireDays = 30;

    public set cookiePath(cookiePath: string) {
        this._cookiePath = cookiePath;
    }

    public get cookiePath(): string {
        return this._cookiePath;
    }

    public set accessToken(accessToken: string) {
        this._accessToken = accessToken;
    }

    public get accessToken(): string {
        return this._accessToken;
    }

    public set accessTokenKey(accessTokenKey: string) {
        this._accessTokenKey = accessTokenKey;
    }

    public get accessTokenKey(): string {
        return this._accessTokenKey;
    }

    public set accessTokenCookieExpireDays(accessTokenCookieExpireDays: number) {
        this._accessTokenCookieExpireDays = accessTokenCookieExpireDays;
    }

    public get accessTokenCookieExpireDays(): number {
        return this._accessTokenCookieExpireDays;
    }

    public write(mode: TokenServiceMode): void {
        if (this._accessToken === null) {
            return;
        }
        switch (mode) {
            case TokenServiceMode.LOCALSTORAGE:
				if (this.localStorage !== undefined) {
					this.localStorage.setItem(this._accessTokenKey, this._accessToken);
				}
                break;
            case TokenServiceMode.SESSIONSTORAGE:
				if (this.sessionStorage !== undefined) {
					this.sessionStorage.setItem(this._accessTokenKey, this._accessToken);
				}
                break;
            case TokenServiceMode.COOKIE:
                this.cookieService.set(this._accessTokenKey, this._accessToken, this._accessTokenCookieExpireDays, this._cookiePath);
                break;
        }
    }

    public read(mode: TokenServiceMode): string {
        switch (mode) {
            case TokenServiceMode.LOCALSTORAGE:
				if (this.localStorage !== undefined) {
					return this.localStorage.getItem(this._accessTokenKey);
				};
				return null;
            case TokenServiceMode.SESSIONSTORAGE:
				if (this.sessionStorage !== undefined) {
					return this.sessionStorage.getItem(this._accessTokenKey);
				};
				return null;
            case TokenServiceMode.COOKIE: {
                const cookieAccessToken: string = this.cookieService.get(this._accessTokenKey);
                return cookieAccessToken !== '' ? cookieAccessToken : null;
            }
            case null:
                return this.read(TokenServiceMode.LOCALSTORAGE) || this.read(TokenServiceMode.SESSIONSTORAGE) || this.read(TokenServiceMode.COOKIE);
        }
    }

    public delete(mode: TokenServiceMode): void {
        switch (mode) {
            case TokenServiceMode.LOCALSTORAGE:
				if (this.localStorage !== undefined) {
					this.localStorage.removeItem(this._accessTokenKey);
				}
                break;
            case TokenServiceMode.SESSIONSTORAGE:
				if (this.sessionStorage !== undefined) {
					this.sessionStorage.removeItem(this._accessTokenKey);
				}
                break;
            case TokenServiceMode.COOKIE: {
                this.cookieService.delete(this._accessTokenKey);
                break;
            }
            case null:
                this.delete(TokenServiceMode.LOCALSTORAGE);
                this.delete(TokenServiceMode.SESSIONSTORAGE);
                this.delete(TokenServiceMode.COOKIE);
                break;
        }
        this._accessToken = null;
    }

    readAccesssToken(): void {
        this._accessToken = this.read(null);
    }

    constructor(
        private cookieService: CookieService
	) {}

}
